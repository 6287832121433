import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUserSubscriptionsResponse, IAllSendersResponse, ISender } from '../../interfaces/sender.interfaces';

const sendersSlice = createSlice({
    name: 'senders',
    initialState: {
        subscribed: {
            items: {
                items: [],
            },
            totalItems: 0,
            pageSize: 0,
            page: 0,
        },
        paused: {
            items: {
                items: [],
                totalItems: 0,
                pageSize: 0,
                page: 0,
            },
        },
        all: {
            items: {
                items: [],
                totalItems: 0,
                pageSize: 0,
                page: 0,
            },
        },
    },
    reducers: {
        setSubscribed: (state, action: PayloadAction<IUserSubscriptionsResponse>) => {
            state.subscribed.items = action.payload;
        },
        setPaused: (state, action: PayloadAction<IUserSubscriptionsResponse>) => {
            state.paused.items = action.payload;
        },
        addPaused: (state, action: PayloadAction<ISender>) => {
            const { id } = action.payload;
            const index = state.paused.items.items.findIndex((item) => item.senderId === id);

            const payload = { ...action.payload, senderId: action.payload?.id };

            if (index === -1) {
                state.paused.items.items.push({ ...payload, sender: payload });
                state.paused.items.totalItems++;
            }
        },
        removePaused: (state, action: PayloadAction<ISender>) => {
            state.paused.items.items = state.paused.items.items.filter((item) => item.senderId !== action.payload.id);
            state.paused.items.totalItems--;
        },
        setAll: (state, action: PayloadAction<IAllSendersResponse>) => {
            state.all.items = action.payload;
        },
        removeSender: (state, action: PayloadAction<{ id: number }>) => {
            state.all.items.items = state.all.items.items.filter((item) => item.id !== action.payload.id);
            state.all.items.totalItems--;
        },
        addSubscribed: (state, action: PayloadAction<ISender>) => {
            state.subscribed.items.items.push({ ...action.payload, sender: action.payload });
        },
    },
});

export const { reducer: sendersReducer } = sendersSlice;
export const { setSubscribed, setPaused, addPaused, setAll, removeSender, removePaused, addSubscribed } =
    sendersSlice.actions;

export interface ISenderState
    extends IUserSubscriptionsResponse,
        Omit<ReturnType<typeof sendersReducer>, keyof IUserSubscriptionsResponse> {}
